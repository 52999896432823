/*
 * Copyright 2021 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {
  Nav,
  NavItem,
  NavList
} from '@patternfly/react-core/dist/js/components/Nav';
import { Link } from 'react-router-dom';
import { ouiaAttribute } from '@kogito-apps/ouia-tools';

interface Props {
  pathname?: string;
}

const TaskConsoleNav: React.FC<Props> = ({ pathname }) => {
  return (
    <Nav aria-label="Nav" theme="dark">
      <NavList>
        <NavItem key={'task-inbox-nav'} isActive={pathname === '/TaskInbox'}>
          <Link
            to="/TaskInbox"
            {...ouiaAttribute('data-ouia-navigation-name', 'task-inbox')}
          >
            Task Inbox
          </Link>
        </NavItem>
      </NavList>
    </Nav>
  );
};

export default TaskConsoleNav;
